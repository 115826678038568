import BaseLayout from '../components/layout/BaseLayout'
import Col from 'react-bootstrap/Col'
import ContactCard from '../components/elements/cards/ContactCard'
import ContactForm from '../components/forms/ContactForm'
import Container from 'react-bootstrap/Container'
//import GoogleMap from '../components/elements/Map'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Section from '../components/layout/Section'
import contactBackground from '../assets/img/pages/contact/background.jpg'
import { css } from '@emotion/react'
import get from 'lodash/get'
import PaymentInformationCard from '../components/elements/cards/PaymentInformationCard'
import Card from '../components/elements/cards/Card'
import makeSeoTitle from '../lib/utils/makeSeoTitle'

const ContactPage = ({ location }) => {
  const email = get(location, 'state.email')

  return (
    <BaseLayout>
      <Section
        backgroundImage={contactBackground}
        css={css`
          background-repeat: no-repeat;
          background-size: cover;
        `}
      >
        <Container
          css={css`
            padding: 0;
          `}
        >
          <Row>
            <Col md={12} lg={7}>
              <div
                css={css`
                  height: 100%;
                  display: flex;
                  align-items: center;
                `}
              >
                <Card dark>
                  <h1
                    css={css`
                      font-size: 1.8rem;
                      margin-bottom: 0.5rem;
                    `}
                  >
                    Kontakt
                  </h1>
                  <p
                    css={css`
                      margin-bottom: 2.5rem;
                    `}
                  >
                    Nutzen Sie gerne nachfolgendes Formular, um mit mir in
                    Kontakt zu treten. Gerne können Sie hier auch Fragen,
                    Wünsche oder Probleme äußern.
                  </p>
                  <ContactForm initialEmail={email} />
                </Card>
              </div>
            </Col>
            <Col md={12} lg={5}>
              <PaymentInformationCard />
              <ContactCard />
            </Col>
          </Row>
        </Container>
      </Section>

      {/*<GoogleMap />*/}
    </BaseLayout>
  )
}

export const Head = () => (
  <>
    <title>{makeSeoTitle('Kontakt')}</title>
    <meta
      name="description"
      content="Treten wir in Kontakt! Bei Fragen, Wünschen oder Problemen stehe ich Ihnen hier zur Verfügung. Formloser Kontakt auch über Whatsapp oder Discord!"
    />
  </>
)

export default ContactPage
