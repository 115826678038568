import * as yup from 'yup'

import React, { useState } from 'react'

import Alert from '../elements/Alert'
import Button from '../elements/buttons/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import SelectField from '../elements/form/SelectField'
import TextField from '../elements/form/TextField'
import TextareaField from '../elements/form/TextareaField'
import apiClient from '../../lib/apiClient'

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Bitte geben Sie eine gültige E-Mail-Adresse ein.')
    .required('Erforderlich'),
  name: yup.string(),
  message: yup
    .string()
    .required('Erforderlich')
    .min(20, 'Die Nachricht muss mindestens 20 Zeichen lang sein.')
    .max(1500, 'Die Nachricht darf maximal 1500 Zeichen lang sein.'),
})

const ContactForm = ({ initialEmail = '' }) => {
  const [submitStatus, setSubmitStatus] = useState(null)

  const submitHandler = (values, { setSubmitting, resetForm }) => {
    apiClient
      .post('/contact', values)
      .then(() => {
        setSubmitStatus('success')
        resetForm()
      })
      .catch(() => {
        setSubmitStatus('error')
      })
      .finally(() => {
        setSubmitting(false)
      })

    setTimeout(() => {
      setSubmitStatus(null)
    }, 10000)
  }

  return (
    <Formik
      initialValues={{ email: initialEmail, name: '', subject: 0, message: '' }}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {({ handleSubmit, isSubmitting }) => (
        <>
          <Alert visible={submitStatus === 'success'}>
            Kontaktanfrage erfolgreich versandt.
          </Alert>
          <Alert variant="error" visible={submitStatus === 'error'}>
            Kontaktanfrage konnte nicht versandt werden.
            <br />
            Bitte versuche es später erneut.
          </Alert>

          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md={12}>
                <Form.Label>E-Mail</Form.Label>
                <TextField name="email" placeholder="max@mustermann.de" />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md={6}>
                <Form.Label>Name/Firma</Form.Label>
                <TextField name="name" placeholder="Max Mustermann" />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>Betreff</Form.Label>
                <SelectField
                  name="subject"
                  isSearchable={false}
                  options={[
                    { label: 'Allgemein', value: 0 },
                    { label: 'Support', value: 1 },
                    { label: 'Fehler melden', value: 2 },
                    { label: 'Sonstiges', value: 3 },
                  ]}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md={12} style={{ height: '100%' }}>
                <Form.Label>Nachricht</Form.Label>
                <TextareaField
                  name="message"
                  placeholder="Deine Nachricht (20-1500 Zeichen)"
                  rows={3}
                />
              </Form.Group>
            </Row>

            <Row>
              <Col md={12}>
                <Button
                  type="submit"
                  variant="primary"
                  block
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Absenden
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Formik>
  )
}
export default ContactForm
