import ButtonLink from '../buttons/ButtonLink'
import React from 'react'
import Socials from '../misc/Socials'
import MailLink from '../links/MailLink'
import styled from '@emotion/styled'
import Card from './Card'
import DashHeading from '../../layout/DashHeading'
import { contactLinkStyle } from '../links/Link'

const StyledMailLink = styled(MailLink)`
  ${contactLinkStyle}
`

const ContactCard = () => (
  <Card dark>
    <DashHeading>E-Mail</DashHeading>
    <p>
      <StyledMailLink mail="contact@eisdesigns.de" />
    </p>

    <DashHeading>Socials</DashHeading>
    {/* <p>
      Du kannst mich bei Fragen oder Anliegen direkt über <b>Discord</b> oder{' '}
      <b>WhatsApp</b> kontaktieren. Hier findest du alle weiteren
      Kontaktmöglichkeiten:
    </p> */}

    <Socials />

    <DashHeading>Telefon &amp; WhatsApp</DashHeading>
    <p>+49 178 5088629</p>

    <ButtonLink
      variant="primary"
      block
      outlined
      href="https://wa.me/491785088629"
      target="_blank"
      rel="noopener noreferrer"
      external
    >
      Auf WhatsApp kontaktieren
    </ButtonLink>
  </Card>
)

export default ContactCard
