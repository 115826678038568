import {
  faDiscord,
  faTeamspeak,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'

import Col from 'react-bootstrap/Col'
import ExternalLink from '../links/ExternalLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Row from 'react-bootstrap/Row'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { contactLinkStyle } from '../links/Link'

const ContactLink = styled(ExternalLink)`
  ${contactLinkStyle}
`

const Socials = () => (
  <Row
    css={css`
      margin-bottom: 15px;

      @media screen and (min-width: 992px) {
        text-align: center;
      }
    `}
  >
    <Col md={4}>
      <ContactLink to="https://wa.me/491785088629">
        <FontAwesomeIcon icon={faWhatsapp} />
        WhatsApp
      </ContactLink>
    </Col>
    <Col md={4}>
      <ContactLink to="https://discord.gg/mAmPdwKWrf">
        <FontAwesomeIcon icon={faDiscord} />
        Discord
      </ContactLink>
    </Col>
    <Col md={4}>
      <ContactLink to="ts3server://ts.strave.de?port=9987">
        <FontAwesomeIcon icon={faTeamspeak} />
        TeamSpeak
      </ContactLink>
    </Col>
  </Row>
)

export default Socials
