import { Field } from 'formik'
import Form from 'react-bootstrap/Form'
import React from 'react'
import { input } from './TextField'

const TextareaField = ({ showValid = false, showInvalid = true, ...props }) => (
  <Field {...props}>
    {({ field, meta: { touched, error } }) => (
      <>
        <Form.Control
          as="textarea"
          css={input}
          isValid={showValid && touched && !error}
          isInvalid={showInvalid && touched && error}
          placeholder={props.placeholder}
          rows={props.rows}
          style={{ height: '100%' }}
          {...field}
        />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </>
    )}
  </Field>
)

export default TextareaField
